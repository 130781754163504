export const generalData ={
  gender: [
    { value: 1, label: 'Masculino' },
    { value: 2, label: 'Femenino' },
    { value: 3, label: 'Otro' },
  ],
  civilStatus: [
    { value: 1, label: 'Soltero/a' },
    { value: 2, label: 'Casado/a' },
    { value: 3, label: 'Divorciado/a' },
    { value: 4, label: 'Viudo/a' },
  ],
}

