export const studentsData = {
    booleanType: [
        { value: 1, label: 'Si' },
        { value: 2, label: 'No' },
    ],
    transType: [
        { value: 1, label: 'Transporte Público' },
        { value: 2, label: 'Caminando' },
        { value: 3, label: 'Bicicleta' },
        { value: 4, label: 'Transporte Privado' },
        { value: 5, label: 'Transporte Personal' },
        { value: 6, label: 'Carpool' },
        { value: 7, label: 'Otro' },
    ],
    cognitiveSkills: [
        { value: 1, label: 'Puede encontrar la manera de obtener lo que quiere aunque alguien se oponga.'},
        { value: 2, label: 'Puede resolver problemas difíciles si se esfuerza lo suficiente.'},
        { value: 3, label: 'Le es fácil persistir en lo que se propone hasta llegar a alcanzar sus metas.'},
        { value: 4, label: 'Tiene confianza en que podría manejar eficazmente acontecimientos inesperados.'},
        { value: 5, label: 'Gracias a sus cualidades y recursos puede superar situaciones imprevistas.'},
        { value: 6, label: 'Cuando se encuentra en dificultades puede permanecer tranquilo porque cuenta con las habilidades necesarias para manejar situaciones difíciles.'},
        { value: 7, label: 'Venga lo que venga, por lo general es capaz de manejarlo.'},
        { value: 8, label: 'Puede resolver la mayoría de los problemas si se esfuerza lo necesario.'},
        { value: 9, label: 'Si se encuentra en una situación difícil, generalmente se le ocurre que es lo que debe hacer.'},
        { value: 10, label: 'Al tener que hacer frente a un problema, generalmente se le ocurren varias alternativas de cómo resolverlo.'},
        { value: 11, label: 'Es capaz de captar con detalle y concentración la información que se le proporcione.'},
    ],
    conduct: [
        { value: 1, label: 'buena' },
        { value: 2, label: 'regular' },
        { value: 3, label: 'mala' },
    ],
    grade: [
        { value: 1, label: '1ro' },
        { value: 2, label: '2do' },
        { value: 3, label: '3ro' },
    ],
    group: [
        { value: 1, label: 'A' },
        { value: 2, label: 'B' },
        { value: 3, label: 'C' },
    ],
    conductType: [
        { value: 1, label: 'Conducta' },
        { value: 2, label: 'Conducta en el hogar' },
        { value: 3, label: 'Conducta en la escuela' },
        { value: 4, label: 'Conducta en la comunidad' },
    ],
    socialBasic: [
        { value: 1, label: 'Escuchar con atención' },
        { value: 2, label: 'Iniciar una conversación' },
        { value: 3, label: 'Mantener una conversación' },
        { value: 4, label: 'Formular una pregunta' },
        { value: 5, label: 'Dar las gracias' },
        { value: 6, label: 'Presentarse' },
        { value: 7, label: 'Presentar a otros' },
        { value: 8, label: 'Hacer un elogio' },
    ],
    socialAdvanced: [
        { value: 1, label: 'Pedir ayuda' },
        { value: 2, label: 'Participar en clase' },
        { value: 3, label: 'Dar instrucciones' },
        { value: 4, label: 'Seguir instrucciones' },
        { value: 5, label: 'Disculparse' },
        { value: 6, label: 'Convencer a los demás de una idea propia' },
    ],
    socialFeelings: [
        { value: 1, label: 'Conocer sus propios sentimientos' },
        { value: 2, label: 'Expresar sentimientos' },
        { value: 3, label: 'Conocer los sentimientos de los demás' },
        { value: 4, label: 'Enfrentarse al enfado de otros' },
        { value: 5, label: 'Expresar afecto' },
        { value: 6, label: 'Resolver el miedo' },
        { value: 7, label: 'Autorrecompensarse' },
    ],
    socialAssault: [
        { value: 1, label: 'Pedir permiso' },
        { value: 2, label: 'Compartir algo propio' },
        { value: 3, label: 'Ayudar a los demás' },
        { value: 4, label: 'Negociar' },
        { value: 5, label: 'Empezar el autocontrol' },
        { value: 6, label: 'Defender sus derechos' },
        { value: 7, label: 'Responder a las bromas' },
        { value: 8, label: 'Evitar los problemas de los demás' },
        { value: 9, label: 'No entrar en peleas cuando no son suyas' },
    ],
    socialStress: [
        { value: 1, label: 'Formular una queja correctamente' },
        { value: 2, label: 'Responder correctamente a una queja' },
        { value: 3, label: 'Demostrar deportividad después de un juego perdido/ganado' },
        { value: 4, label: 'Lidiar correctamente con la vergüenza' },
        { value: 5, label: 'Responder a la persuasión' },
        { value: 6, label: 'Responder al fracaso' },
        { value: 7, label: 'Responder a la acusación' },
    ],
    socialPlanning: [
        { value: 1, label: 'Tomar decisiones realistas' },
        { value: 2, label: 'Establecer un objetivo' },
        { value: 3, label: 'Determinar sus propias habilidades' },
        { value: 4, label: 'Recolectar información' },
        { value: 5, label: 'Resolver los problemas según su importancia' },
        { value: 6, label: 'Tomar decisiones eficaces' },
        { value: 7, label: 'Concentrarse en una tarea' },
    ],
    alternativeSkills: [
        { value: 1, label: 'No entrar en peleas: Sabe solucionar problemas evitando las peleas, los gritos y las voces altas.' },
        { value: 2, label: 'Emplear el autocontrol: Se toma un momento para tranquilizarse antes de cometer errores que lo harían arrepentirse (no es impulsivo).' },
        { value: 3, label: 'Responder a la bromas: Sabe reaccionar correctamente ante este tipo de situaciones, no utiliza la inhibición ni la agresividad para transmitir lo que siente y piensa.' },
        { value: 4, label: 'Convencer a los demás: Sabe hablar para pedirle a alguien que deje de hacer algo o que cambie de opinión.' },
        { value: 5, label: 'Negociar: Sabe ponerse de acuerdo con personas que tienen opiniones distintas a la suya.' },
        { value: 6, label: 'Compartir algo: Sabe compartir cosas, objetos, pensamientos e ideas de lo que siente con los demás.' },
        { value: 7, label: 'Ayudar a los demás.' },
    ],

}
